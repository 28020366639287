import React, { useEffect, useState } from "react";
import { navigate } from "gatsby";
import { createUseStyles, useTheme } from "../../styling";
import classes from "./miniNav.module.css";

// const useStyles = createUseStyles({
// 	miniNav: {
// 		position: "sticky",
// 		top: "94px",
// 		height: "max-content",
// 		width: "max-content",
// 		marginRight: "38px",
// 		marginLeft: "50px",
// 		'& span': {
// 			cursor: "pointer",
// 			paddingBottom: "9px",
// 			borderLeft: "1px solid #DDE2E9",
// 			fontFamily: "Inter",
// 			fontWeight: 400,
// 			color: "#3D495B",
// 			fontSize: "13px",
// 			whiteSpace: "nowrap",
// 			'& code': {
// 				border: "1px solid #DDE2E9",
// 				borderColor: "#F9FAFB",
// 				color: "#AA51F0",
// 				padding: "2px 4px",
// 				borderRadius: "4px",
// 				fontSize: "13px"
// 			}
// 		}
// 	},
// 	H2: {
// 		paddingLeft: "20px"
// 	},
// 	H3: {
// 		paddingLeft: "30px"
// 	},
// 	miniWrapper: {
// 		display: "flex",
// 		flexDirection: "column"
// 	},
// 	miniHeader: {
// 		display: "flex",
// 		alignItems: "center",
// 		marginBottom: "13px",
// 		'& h6': {
// 			marginBottom: "0px",
// 			color: "#8EA1BE",
// 			fontFamily: "Inter",
// 			fontWeight: 500,
// 			fontSize: "12px",
// 			marginTop: "0px"
// 		}
// 	},
// 	icon: {
// 		width: "8px",
// 		height: "8px",
// 		color: "#5A687B"
// 	},
// 	'@media screen and (max-width: 1201px)': {
// 		miniNav: {
// 			display: "none"
// 		}
// 	}
// });

interface IMiniContent {
    name: string,
    id: string,
    type: "H3" | "H2"
}

// TODO: FZBZ-601 styling, add a smooth scroll animation
const MiniNav = () => {
    // const classes = useStyles();
    const [minNav, setMiniNav] = useState<IMiniContent[]>([]);
    useEffect(() => {
        let tmpNav: IMiniContent[] = [];
        document.querySelectorAll("h2, h3").forEach(header => {
            if (header.nodeName === 'H3' || header.nodeName === 'H2') {
                tmpNav.push({name: header.textContent || header.innerHTML, id: header.id, type: header.nodeName});
            }
        })
        setMiniNav(tmpNav);
    },[])

    return (
        <div className={classes.miniNav}> 
            <div className={classes.miniWrapper}>
                <div className={classes.miniHeader}><h6>ON THIS PAGE</h6></div>
                {   
                    minNav?.map(({id, name, type}) => (
                        <span 
                            key={id+name} 
                            className={classes[type]} 
                            onClick={()=> {
                                navigate(`#${id}`)
                            }}>
                                {name}
                        </span>
                    ))
                }   
            </div>
        </div> 
    )
}

export default MiniNav ;