// export const adjustBreadCrumbs = (crumbs) => {
export const adjustBreadCrumbs = (crumbs: ICrumbs[]) => {
    let newCrumbs: ICrumbs[] = [];
    crumbs?.forEach(crumb => {
        let splitCrumb = crumb?.crumbLabel?.split("-");
        let tmpCrumbLabel = "";
        splitCrumb.forEach(word => {
            tmpCrumbLabel += word[0].toUpperCase() + word.substr(1) + " ";
        })
        newCrumbs.push({pathname: crumb.pathname, crumbLabel: tmpCrumbLabel});
    });
    return newCrumbs;
}