import React from "react";
import { graphql } from "gatsby";
import { createUseStyles } from "react-jss";
import { Breadcrumb } from 'gatsby-plugin-breadcrumb';
import { MDXRenderer } from "gatsby-plugin-mdx";
import Layout from "../components/layout";
import SEO from "../components/seo";
import { adjustBreadCrumbs } from "../components/util/adjustBreadCrumbs";
import MiniNav from "../components/miniNav";
const useStyles = createUseStyles({
  content: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
  },
  breadcrumbs: {
    display: "flex",
  },
  docBody: {
    display: "flex",
    paddingRight: "36px",
    paddingLeft: "36px",
    '& p': {
      fontSize: "15px",
      lineHeight: "26px",
    },
  },
  docMiniNav: {
    paddingLeft: "35px",
  },
  contentWrapper: {
    width: "100%",
    minWidth: "100%",
    display: "flex",
    flexDirection: "column",
    height: "100%",
    fontFamily: "inter",
    marginBottom: "100px",
    '& hr': {
      backgroundColor: "#DDE2E9",
      height: "1px",
      border: 0,
      width: "100%",
    },
    '& p': {
      fontSize: "15px",
      lineHeight: "26px",
    },
    '& h3': {
      color: "#18212E",
      fontFamily: "Inter",
      fontSize: "24px",
      fontWeight: 600,
      marginTop: "32px",
      marginBottom: 0,
      paddingBottom: "10px",
      borderBottom: "1px solid #EEF0F4",
      '& code': {
        fontSize: "18px",
        lineHeight: "150%",
        borderRadius: "4px",
        fontWeight: 400,
      },
    },
    '& h4': {
      color: "#18212E",
      fontFamily: "Inter",
      fontSize: "18px",
      fontWeight: 500,
      marginBottom: "0px",
      marginTop: "30px",
      display: "flex",
      alignItems: "center",
    },
    '& ul li code, & > ol li code': {
      display: "inline-block",
      marginTop: "3px",
      fontWeight: 500,
    },
    '& ul li, & > ol li': {
      paddingLeft: "15px",
      lineHeight: "150%",
	    color: "#252F3F",
      fontWeight: 500,
    },
    '& ul li::marker': {
      fontSize: "110%",
      color: "#0F172A",
    },
    '& table': {
      marginTop: "15px",
      marginBottom: "15px",
    },
    '& th': {
      fontWeight: 500,
      fontSize: "14px",
      color: "#0F172A",
      textAlign: "left",
      borderBottom: "1px solid #EEF0F4",
      paddingBottom: "8px",
    },
    '& td': {
      borderBottom: "1px solid #EEF0F4",
      textAlign: "left",
      paddingTop: "8px",
      paddingBottom: "8px",
      paddingRight: "8px",
      fontSize: "14px",
      color: "#252F3F",
      fontWeight: 400,

    },
    '& > img': {
      marginTop: "10px",
      marginBottom: "10px",
	  width: "90%",
	  marginLeft: "5%",
    },
  },
  pageHeader: {
    display: "flex",
    flexDirection: "column",
    borderBottom: "1px solid #DDE2E9",
    marginBottom: "25px",
    '& h1': {
      marginTop: "10px",
      marginBottom: "10px",
      fontFamily: "Inter",
    },
    '& span': {
      marginBottom: "21px",
      lineHeight: "26px",
      color: "#3D495B",
    },
  },
  '@media screen and (max-width: 1201px)': {
    docMiniNav: {
      paddingLeft: "0px",
    },
  },
  '@media screen and (max-width: 770px)': {
    docBody: {
      paddingRight: "17px",
      paddingLeft: "17px",
    },
  }
})

interface ICrumbs {
  pathname: string,
  crumbLabel: string
}

interface IPageContext {
  breadcrumb: {
    location: string,
    crumbs: ICrumbs[],
    id: string,
    nextPostId: string,
    previousPostId: string,
  }
}

interface IData {
  mdx: {
    body: string,
    frontmatter: {
      title: string,
      description?: string,
    },
    id: string,
  },
  next: {
    fields: {
      slug: string,
    },
    frontmatter: {
      title: string,
    },
  },
  previous: {
    fields: {
      slug: string,
    },
    frontmatter: {
      title: string,
    },
  },
  site: {
    siteMetaData: {
      title: string,
    },
  },
}

// TODO: FZBZ-651 next/previous links
const DocPageTemplate = ({ pageContext, data, location }: { pageContext: IPageContext, data: IData, location: Location }) => {
  const classes = useStyles();
  const { body, frontmatter } = data.mdx;
  const isRootPath = location.pathname === process.env.ROOT && `/`;
  const pathSplit = location.pathname.split("/");
  const isRootNav = pathSplit.length === 2 || (pathSplit.length > 2 && pathSplit[2] === "");
  const { breadcrumb: { crumbs } } = pageContext;
  const adjustedCrumbs: ICrumbs[] = adjustBreadCrumbs(crumbs);
  return (
    <Layout location={location}>
      <SEO
        title={frontmatter.title}
        description={frontmatter.description}
        lang={"en"}
      />
      <div className={classes.docBody}>
        <div className={classes.contentWrapper}>
          {
            adjustedCrumbs?.length > 2 && <Breadcrumb crumbs={adjustedCrumbs.slice(1)} crumbSeparator=" / " />
          }
          <MDXRenderer className={classes.content}>
            {body}
          </MDXRenderer>
        </div>
        {
          !isRootPath && !isRootNav &&
          <div className={classes.docMiniNav}>
            <MiniNav />
          </div>
        }
      </div>
    </Layout>
  )
}

export default DocPageTemplate;

export const pageQuery = graphql`
  query DocPageQuery(
    $id: String!
    ) {
    site {
      siteMetadata {
        title
      }
    }
    mdx(id: { eq: $id }) {
      id
      body
      frontmatter {
        title
        description
      }
    }
  }`